export default {
  progress: {
    style: {
      width: '100%',
      height: '50vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    color: '#72218A',
    size: 100
  }
};
